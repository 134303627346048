import React, { useState, useRef, useEffect } from "react"
import tw, { styled, css } from "twin.macro"
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { jsx, keyframes } from '@emotion/react'
import {
	faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import Icon from "../../components/icon"
import mq from '../../styles/media-queries'
import WallpaperBackgroundImage from "../../components/wallpaperBackgroundImage"
import { useTheme } from '../../hooks/useTheme'
import md from '../../styles/media-queries'
import useScript from '../../hooks/useScript'

export const theme = {
	nav: {
		[md.sm] : {
			"background" : "0",
			"backgroundColor" : "rgba(0, 0, 0, 0.3)"
		}
	}
}

const Ad = (props) => {

	const { setTheme } = useTheme()
	
	useEffect(() => {
		setTheme(theme)
	}, [])

	window.corner_video = "left"
	window.zone_id = 504
	window.mobile_allowed=true
	window.site_id = 5089

	const scriptStatus = useScript('https://serv-vdo.pixfuture.com/vpaid/ads.js')

	return (
		<div tw="w-full h-full absolute bg-blue">

			<div id="pixfuture504" tw="bg-red" style={{height:"1px"}}></div>

			{/* <div tw="w-full h-full absolute flex flex-col justify-end">
				<div tw="h-2/5 flex flex-col justify-center">
				<div tw="
					flex items-center justify-center
					h-3/5
				"
				>
					<div tw="opacity-80" css={[{
						backgroundColor: "#498EE2"
					},css`
						
						animation: ${anim} 1s 2s ease 1 forwards
					`]}>
					</div>
				</div>
				</div>
			</div>
			<div tw="w-full h-full absolute flex flex-col justify-end">
				<div tw="h-2/5 flex flex-col justify-center">
					<div tw="
						flex items-center justify-center
						h-3/5
					"
					>
						<div tw="
							h-full
							opacity-0
							flex 
							flex-col
							items-center
							justify-center
							bg-opacity-100
						" 
						css={css`
							animation: ${appear} 1.0s 3.5s forwards
						`}>
							<div tw="
								flex-1
								flex 
								flex-col 
								items-center 
								justify-center
								text-2xl
								2xl:text-3xl
								3xl:text-4xl
							">
								<div tw="
									flex justify-center items-center text-white font-bold 
									mb-4
									2xl:mb-7
									3xl:mb-8
								">Advertise on file.io!</div>
								<div tw="p-3 2xl:p-4 3xl:p-5 rounded-md bg-green text-white flex items-center">
									<div tw="font-bold" >Get More Info</div>
									<Icon tw="inline h-6 ml-3" icon={faChevronRight}></Icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
		</div>
	)
}

export default Ad
